import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { MatchCard } from '../shared/MatchCard';

const StatBar = ({ label, value1, value2, isPercentage = false, selectedTeam = 1 }) => {
    const total = value1 + value2;
    const percent1 = total === 0 ? 50 : (value1 / total) * 100;
    const percent2 = total === 0 ? 50 : (value2 / total) * 100;

    return (
        <div className="space-y-2">
            <div className="flex justify-between text-gray-600 text-sm">
                <span>{value1}</span>
                <span>{label}</span>
                <span>{value2}</span>
            </div>
            <div className="flex h-2 rounded-full overflow-hidden">
                <div 
                    className={selectedTeam === 1 ? 'bg-orange-500' : 'bg-gray-500'}
                    style={{ width: `${percent1}%` }}
                />
                <div 
                    className={selectedTeam === 2 ? 'bg-orange-500' : 'bg-gray-500'}
                    style={{ width: `${percent2}%` }}
                />
            </div>
        </div>
    );
};

export const MatchDetail = ({ program, standings, results, selectedTeam }) => {
    const { t } = useTranslation();
    const { matchId } = useParams();
    const navigate = useNavigate();

    // Find the match from the program
    const match = program.find((m, index) => index.toString() === matchId);

    if (!match) {
        return (
            <div>
                <div className="container mx-auto px-4 py-8">
                    <div className="bg-white rounded-lg shadow-md p-4">
                        <div className="mb-1">
                            <h2 className="text-sm font-bold uppercase tracking-wide">{t('match.notFound')}</h2>
                        </div>
                        <div className="h-0.5 bg-orange-500 mb-4"></div>
                        <button
                            onClick={() => navigate(-1)}
                            className="flex items-center gap-2 text-gray-600 hover:text-gray-800"
                        >
                            <ChevronLeft className="w-4 h-4" />
                            {t('common.goBack')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    // Calculate team statistics from results
    const calculateTeamStats = (teamName) => {
        const teamResults = results.filter(r => r.team1 === teamName || r.team2 === teamName);
        let stats = {
            wins: 0,
            draws: 0,
            losses: 0,
            goalsFor: 0,
            goalsAgainst: 0,
            currentStreak: 0,
            longestWinStreak: 0,
            currentWinStreak: 0
        };

        let currentWinStreak = 0;
        teamResults.forEach(result => {
            const [score1, score2] = result.score.split('-').map(Number);
            const isTeam1 = result.team1 === teamName;
            const teamScore = isTeam1 ? score1 : score2;
            const opponentScore = isTeam1 ? score2 : score1;

            stats.goalsFor += teamScore;
            stats.goalsAgainst += opponentScore;

            if (teamScore > opponentScore) {
                stats.wins++;
                currentWinStreak++;
                stats.longestWinStreak = Math.max(stats.longestWinStreak, currentWinStreak);
            } else {
                currentWinStreak = 0;
                if (teamScore === opponentScore) {
                    stats.draws++;
                } else {
                    stats.losses++;
                }
            }
        });

        stats.currentWinStreak = currentWinStreak;
        return stats;
    };

    const team1Stats = calculateTeamStats(match.team1);
    const team2Stats = calculateTeamStats(match.team2);

    return (
        <div>
            <div className="container mx-auto px-4 py-4">
                <button
                    onClick={() => navigate(-1)}
                    className="flex items-center gap-2 text-gray-600 hover:text-gray-800 mb-4"
                >
                    <ChevronLeft className="w-4 h-4" />
                    {t('common.goBack')}
                </button>

                <MatchCard
                    match={match}
                    standings={standings}
                    results={results}
                    title={t('match.details')}
                />

                <div className="bg-white rounded-lg shadow-md p-4">
                    <div className="mb-1">
                        <h2 className="text-sm font-bold uppercase tracking-wide">{t('match.statistics')}</h2>
                    </div>
                    <div className="h-0.5 bg-orange-500 mb-4"></div>
                    <div className="space-y-6">
                        <StatBar 
                            label={t('standings.won')}
                            value1={team1Stats.wins}
                            value2={team2Stats.wins}
                            selectedTeam={match.team1 === selectedTeam ? 1 : match.team2 === selectedTeam ? 2 : null}
                        />
                        <StatBar 
                            label={t('match.draws')}
                            value1={team1Stats.draws}
                            value2={team2Stats.draws}
                            selectedTeam={match.team1 === selectedTeam ? 1 : match.team2 === selectedTeam ? 2 : null}
                        />
                        <StatBar 
                            label={t('standings.lost')}
                            value1={team1Stats.losses}
                            value2={team2Stats.losses}
                            selectedTeam={match.team1 === selectedTeam ? 1 : match.team2 === selectedTeam ? 2 : null}
                        />
                        <StatBar 
                            label={t('match.goalsScored')}
                            value1={team1Stats.goalsFor}
                            value2={team2Stats.goalsFor}
                            selectedTeam={match.team1 === selectedTeam ? 1 : match.team2 === selectedTeam ? 2 : null}
                        />
                        <StatBar 
                            label={t('match.goalsAgainst')}
                            value1={team1Stats.goalsAgainst}
                            value2={team2Stats.goalsAgainst}
                            selectedTeam={match.team1 === selectedTeam ? 1 : match.team2 === selectedTeam ? 2 : null}
                        />
                        <StatBar 
                            label={t('match.averageGoalsScored')}
                            value1={Number((team1Stats.goalsFor / (team1Stats.wins + team1Stats.draws + team1Stats.losses)).toFixed(2))}
                            value2={Number((team2Stats.goalsFor / (team2Stats.wins + team2Stats.draws + team2Stats.losses)).toFixed(2))}
                            selectedTeam={match.team1 === selectedTeam ? 1 : match.team2 === selectedTeam ? 2 : null}
                        />
                        <StatBar 
                            label={t('match.longestWinStreak')}
                            value1={team1Stats.longestWinStreak}
                            value2={team2Stats.longestWinStreak}
                            selectedTeam={match.team1 === selectedTeam ? 1 : match.team2 === selectedTeam ? 2 : null}
                        />
                        <StatBar 
                            label={t('match.currentWinStreak')}
                            value1={team1Stats.currentWinStreak}
                            value2={team2Stats.currentWinStreak}
                            selectedTeam={match.team1 === selectedTeam ? 1 : match.team2 === selectedTeam ? 2 : null}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}; 