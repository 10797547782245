import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COLUMN_NAMES } from '../utils/constants';

export const Standings = ({ standings, selectedTeam }) => {
    const [showDetailed, setShowDetailed] = useState(false);
    const { t } = useTranslation();

    return (
        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="flex justify-between items-center mb-1">
                <h2 className="text-sm font-bold uppercase tracking-wide">{t('navigation.standings')}</h2>
                <label className="inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={showDetailed}
                        onChange={(e) => setShowDetailed(e.target.checked)}
                        className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500"></div>
                    <span className="ml-2 text-sm font-medium text-gray-700">{t('standings.showDetailed')}</span>
                </label>
            </div>
            <div className="h-0.5 bg-orange-500 mb-4"></div>
            <div className="overflow-x-auto">
                <table className="w-full">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="p-2 text-left w-5">#</th>
                            <th className="p-2 text-left">{t('standings.team')}</th>
                            <th className="p-2 text-center w-12" title={t('standings.played')}>P</th>
                            {showDetailed && (
                                <>
                                    <th className="p-2 text-center w-10" title={t('standings.won')}>W</th>
                                    <th className="p-2 text-center w-10" title={t('standings.draw')}>D</th>
                                    <th className="p-2 text-center w-10" title={t('standings.lost')}>L</th>
                                    <th className="p-2 text-center w-10" title={t('standings.goalsFor')}>GF</th>
                                    <th className="p-2 text-center w-10" title={t('standings.goalsAgainst')}>GA</th>
                                </>
                            )}
                            <th className="p-2 text-center w-16" title={t('standings.points')}>Pts</th>
                        </tr>
                    </thead>
                    <tbody>
                        {standings.map((team) => (
                            <tr key={team.position} className={team.team === selectedTeam ? 'bg-gray-200' : ''}>
                                <td className="p-2 text-left w-5">{team.position}</td>
                                <td className={team.team === selectedTeam ? 'p-2 font-bold' : 'p-2'}>{team.team}</td>
                                <td className={team.team === selectedTeam ? 'p-2 text-center font-bold' : 'p-2 text-center'}>{team.played}</td>
                                {showDetailed && (
                                    <>
                                        <td className={team.team === selectedTeam ? 'p-2 text-center font-bold' : 'p-2 text-center'}>{team.won}</td>
                                        <td className={team.team === selectedTeam ? 'p-2 text-center font-bold' : 'p-2 text-center'}>{team.draw}</td>
                                        <td className={team.team === selectedTeam ? 'p-2 text-center font-bold' : 'p-2 text-center'}>{team.lost}</td>
                                        <td className={team.team === selectedTeam ? 'p-2 text-center font-bold' : 'p-2 text-center'}>{team.goalsFor}</td>
                                        <td className={team.team === selectedTeam ? 'p-2 text-center font-bold' : 'p-2 text-center'}>{team.goalsAgainst}</td>
                                    </>
                                )}
                                <td className={team.team === selectedTeam ? 'p-2 text-center font-bold' : 'p-2 text-center'}>{team.points}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}; 