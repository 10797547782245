import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock, MapPin, CalendarPlus, ChevronRight } from 'lucide-react';
import ICalendarLink from 'react-icalendar-link';
import { formatShortDate, formatShortTime, getCalendarEvent } from '../utils/dateUtils';
import { Pagination } from '../shared/Pagination';
import { CONFIG } from '../utils/constants';
import { useTranslation } from 'react-i18next';

export const Program = ({ program, selectedTeam }) => {
    const [showAll, setShowAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const filteredProgram = program.filter(match =>
        showAll || match.team1 === selectedTeam || match.team2 === selectedTeam
    );

    const totalPages = Math.ceil(filteredProgram.length / CONFIG.ITEMS_PER_PAGE);
    const paginatedProgram = filteredProgram.slice(
        (currentPage - 1) * CONFIG.ITEMS_PER_PAGE,
        currentPage * CONFIG.ITEMS_PER_PAGE
    );

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleMatchClick = (matchIndex) => {
        navigate(`/match/${matchIndex}`);
    };

    return (
        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="flex justify-between items-center mb-1">
                <h2 className="text-sm font-bold uppercase tracking-wide">{t('navigation.program')}</h2>
                <label className="inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={showAll}
                        onChange={() => {
                            setShowAll(!showAll);
                            setCurrentPage(1);
                        }}
                        className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500"></div>
                    <span className="ml-2 text-sm font-medium text-gray-700">{t('program.showAll')}</span>
                </label>
            </div>
            <div className="h-0.5 bg-orange-500 mb-4"></div>
            <div className="min-h-[280px] space-y-4">
                {paginatedProgram.map((match, index) => {
                    const originalIndex = program.findIndex(m => 
                        m.team1 === match.team1 && 
                        m.team2 === match.team2 && 
                        m.date === match.date
                    );
                    return (
                        <div key={index} className="pb-3 cursor-pointer" onClick={() => handleMatchClick(originalIndex)}>
                            <ICalendarLink
                                event={getCalendarEvent(match)}
                                className="flex justify-between items-center mb-3 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors px-3 py-1.5"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <span>{formatShortDate(match.date)}</span>
                                <CalendarPlus className="w-4 h-4 text-gray-700" />
                            </ICalendarLink>
                            <div className="flex justify-between items-center group">
                                <div className="space-y-1">
                                    <div className={match.team1 === selectedTeam ? 'font-bold' : ''}>
                                        {match.team1}
                                    </div>
                                    <div className={match.team2 === selectedTeam ? 'font-bold' : ''}>
                                        {match.team2}
                                    </div>
                                </div>
                                <div className="flex items-center gap-4">
                                    <div className="space-y-2 text-right">
                                        <div className="flex items-center justify-end">
                                            <MapPin className="w-4 h-4 mr-1 text-gray-500" />
                                            <span>{match.location}</span>
                                        </div>
                                        <div className="flex items-center justify-end">
                                            <Clock className="w-4 h-4 mr-1 text-gray-500" />
                                            <span>{formatShortTime(match.time)}</span>
                                        </div>
                                    </div>
                                    <ChevronRight className="w-4 h-4 text-gray-400" />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {totalPages > 1 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            )}
        </div>
    );
}; 