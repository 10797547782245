import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MatchCard } from '../shared/MatchCard';
import { formatShortDate } from '../utils/dateUtils';

export const NextMatch = ({ match, standings, results, program }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = () => {
        if (!program || !match) return;
        
        const matchIndex = program.findIndex(m => 
            m.team1 === match.team1 && 
            m.team2 === match.team2 && 
            m.date === match.date &&
            m.time === match.time
        );
        
        if (matchIndex !== -1) {
            navigate(`/match/${matchIndex}`);
        }
    };

    return (
        <MatchCard
            match={match ? { ...match, date: formatShortDate(match.date) } : null}
            standings={standings}
            results={results}
            title={t('match.nextMatch')}
            showNavigationArrow={true}
            onClick={match ? handleClick : undefined}
        />
    );
}; 