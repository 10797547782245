import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainView from './components/MainView';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<MainView />} />
                    <Route path="/match/:matchId" element={<MainView showMatchDetail={true} />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
