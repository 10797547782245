import { useState, useEffect, useCallback } from 'react';
import { CONFIG } from '../utils/constants';

export const useCompetitionData = () => {
    const [selectedSeason, setSelectedSeason] = useState('');
    const [seasons, setSeasons] = useState([]);
    const [selectedCompetition, setSelectedCompetition] = useState('');
    const [selectedTeam, setSelectedTeam] = useState('');
    const [availableTeams, setAvailableTeams] = useState([]);
    const [competitions, setCompetitions] = useState([]);
    const [standings, setStandings] = useState([]);
    const [results, setResults] = useState([]);
    const [program, setProgram] = useState([]);
    const [nextMatch, setNextMatch] = useState(null);
    const [lastResult, setLastResult] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isEditingPreferences, setIsEditingPreferences] = useState(true);
    const [activeCompetition, setActiveCompetition] = useState('');
    const [activeSeason, setActiveSeason] = useState('');

    const fetchSeasons = async () => {
        try {
            setIsLoading(true);
            const response = await fetch('/api/seasons');
            const data = await response.json();
            if (!response.ok) throw new Error(data.error || 'Failed to load seasons');
            setSeasons(data);
            setError(null);
        } catch (error) {
            console.error('Error fetching seasons:', error);
            setError('Failed to load seasons');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCompetitions = async (seasonId) => {
        if (!seasonId) return;
        try {
            setIsLoading(true);
            const response = await fetch(`/api/competitions/${seasonId}`);
            const data = await response.json();
            if (!response.ok) throw new Error(data.error || 'Failed to load competitions');
            setCompetitions(data);
            setError(null);
        } catch (error) {
            console.error('Error fetching competitions:', error);
            setError('Failed to load competitions');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAllData = useCallback(async (seasonId, pouleId) => {
        if (!seasonId || !pouleId) return;
        try {
            setIsLoading(true);
            setIsRefreshing(true);
            setProgram([]); 
            setResults([]); 
            setStandings([]); 
            
            const response = await fetch(`/api/all-data/${seasonId}/${pouleId}`);
            const data = await response.json();
            
            if (!response.ok) throw new Error(data.error || 'Failed to load data');
            
            setStandings(data.standings || []);
            setResults(data.results || []);
            setProgram(data.program || []);
            
            const teams = (data.standings || []).map(standing => ({
                name: standing.team
            }));
            setAvailableTeams(teams);
            setError(null);
            setLastUpdated(new Date());
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to load data');
            setStandings([]);
            setResults([]);
            setProgram([]);
            setAvailableTeams([]);
        } finally {
            setIsLoading(false);
            setIsRefreshing(false);
        }
    }, []);

    const fetchTeamsForCompetition = useCallback(async (seasonId, pouleId) => {
        if (!seasonId || !pouleId) return;
        try {
            const response = await fetch(`/api/all-data/${seasonId}/${pouleId}`);
            const data = await response.json();
            
            if (!response.ok) throw new Error(data.error || 'Failed to load data');
            
            const teams = (data.standings || []).map(standing => ({
                name: standing.team
            }));
            setAvailableTeams(teams);
            setStandings(data.standings || []);
            setResults(data.results || []);
            setProgram(data.program || []);
            setError(null);
        } catch (error) {
            console.error('Error fetching teams:', error);
            setAvailableTeams([]);
            setStandings([]);
            setResults([]);
            setProgram([]);
            setError('Failed to load teams');
        }
    }, []);

    const updateNextMatchAndLastResult = useCallback(() => {
        if (!selectedTeam || !program || !results) {
            setNextMatch(null);
            setLastResult(null);
            return;
        }

        const teamNextMatch = program.find(match =>
            match && (match.team1 === selectedTeam || match.team2 === selectedTeam)
        );
        setNextMatch(teamNextMatch || null);

        const teamLastResult = results.find(result =>
            result && (result.team1 === selectedTeam || result.team2 === selectedTeam)
        );
        setLastResult(teamLastResult || null);
    }, [selectedTeam, program, results]);

    useEffect(() => {
        const savedSeason = localStorage.getItem('userSeason');
        const savedCompetition = localStorage.getItem('userCompetition');
        const savedTeam = localStorage.getItem('userTeam');

        if (savedSeason && savedCompetition && savedTeam) {
            setSelectedSeason(savedSeason);
            setActiveSeason(savedSeason);
            setSelectedCompetition(savedCompetition);
            setSelectedTeam(savedTeam);
            setActiveCompetition(savedCompetition);
            setIsEditingPreferences(false);
        }

        fetchSeasons();

        const refreshInterval = setInterval(() => {
            if (activeSeason && activeCompetition) {
                fetchAllData(activeSeason, activeCompetition);
            }
        }, CONFIG.AUTO_REFRESH_INTERVAL);

        return () => clearInterval(refreshInterval);
    }, [activeSeason, activeCompetition, fetchAllData]);

    useEffect(() => {
        if (selectedSeason) {
            fetchCompetitions(selectedSeason);
        }
    }, [selectedSeason]);

    useEffect(() => {
        if (activeSeason && activeCompetition) {
            fetchAllData(activeSeason, activeCompetition);
        }
    }, [activeSeason, activeCompetition, fetchAllData]);

    useEffect(() => {
        if (selectedTeam) {
            updateNextMatchAndLastResult();
        }
    }, [selectedTeam, program, results, updateNextMatchAndLastResult]);

    useEffect(() => {
        if (selectedSeason && selectedCompetition && isEditingPreferences) {
            fetchTeamsForCompetition(selectedSeason, selectedCompetition);
        }
    }, [selectedSeason, selectedCompetition, isEditingPreferences, fetchTeamsForCompetition]);

    const handleSavePreferences = () => {
        localStorage.setItem('userSeason', selectedSeason);
        localStorage.setItem('userCompetition', selectedCompetition);
        localStorage.setItem('userTeam', selectedTeam);
        setActiveSeason(selectedSeason);
        setActiveCompetition(selectedCompetition);
        setIsEditingPreferences(false);
    };

    const handleEditPreferences = () => {
        setIsEditingPreferences(true);
    };

    const handleRefresh = () => {
        if (activeSeason && activeCompetition && !isRefreshing) {
            fetchAllData(activeSeason, activeCompetition);
        }
    };

    return {
        selectedSeason,
        setSelectedSeason,
        seasons,
        selectedCompetition,
        setSelectedCompetition,
        selectedTeam,
        setSelectedTeam,
        availableTeams,
        competitions,
        standings,
        results,
        program,
        nextMatch,
        lastResult,
        isLoading,
        error,
        lastUpdated,
        isRefreshing,
        isEditingPreferences,
        handleSavePreferences,
        handleEditPreferences,
        handleRefresh
    };
}; 