export const CONFIG = {
    ITEMS_PER_PAGE: 10,
    AUTO_REFRESH_INTERVAL: 5 * 60 * 1000, // 5 minutes
};

export const DUTCH_MONTHS = [
    'januari', 'februari', 'maart', 'april', 'mei', 'juni',
    'juli', 'augustus', 'september', 'oktober', 'november', 'december'
];

export const COLUMN_NAMES = {
    p: "Played",
    w: "Won",
    d: "Draw",
    l: "Lost",
    pts: "Points",
    gf: "Goals For",
    ga: "Goals Against"
}; 