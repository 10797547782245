import React from 'react';
import { useTranslation } from 'react-i18next';

export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const { t } = useTranslation();
    
    return (
        <div className="flex justify-end items-center mt-4 space-x-2">
            <div className="text-sm text-gray-600 bg-gray-100 px-4 py-2 rounded-md">
                {t('pagination.pageInfo', { current: currentPage, total: totalPages })}
            </div>
            <div className="flex space-x-1">
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="w-10 h-10 flex items-center justify-center border rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:hover:bg-white"
                    aria-label={t('pagination.previous')}
                >
                    ‹
                </button>
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="w-10 h-10 flex items-center justify-center border rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:hover:bg-white"
                    aria-label={t('pagination.next')}
                >
                    ›
                </button>
            </div>
        </div>
    );
}; 