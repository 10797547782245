import React, { useState } from 'react';
import { Pagination } from '../shared/Pagination';
import { CONFIG } from '../utils/constants';
import { useTranslation } from 'react-i18next';

export const Results = ({ results, selectedTeam }) => {
    const [showAll, setShowAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = useTranslation();

    const filteredResults = results.filter(result => 
        showAll || result.team1 === selectedTeam || result.team2 === selectedTeam
    );

    const totalPages = Math.ceil(filteredResults.length / CONFIG.ITEMS_PER_PAGE);
    const paginatedResults = filteredResults.slice(
        (currentPage - 1) * CONFIG.ITEMS_PER_PAGE,
        currentPage * CONFIG.ITEMS_PER_PAGE
    );

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const getWinnerStyle = (score, isTeam1) => {
        if (!score) return '';
        const [score1, score2] = score.split('-').map(Number);
        if (score1 === score2) return '';
        if ((isTeam1 && score1 > score2) || (!isTeam1 && score2 > score1)) {
            return 'font-bold';
        }
        return '';
    };

    return (
        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="flex justify-between items-center mb-1">
                <h2 className="text-sm font-bold uppercase tracking-wide">{t('navigation.results')}</h2>
                <label className="inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={showAll}
                        onChange={() => {
                            setShowAll(!showAll);
                            setCurrentPage(1);
                        }}
                        className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500"></div>
                    <span className="ml-2 text-sm font-medium text-gray-700">{t('program.showAll')}</span>
                </label>
            </div>
            <div className="h-0.5 bg-orange-500 mb-4"></div>
            <div className="min-h-[280px] space-y-2">
                {paginatedResults.map((result, index) => (
                    <div key={index} className={`p-3 rounded-md ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                        <div className="space-y-2">
                            <div className="flex justify-between items-center">
                                <div className={`'text-gray-600' ${getWinnerStyle(result.score, true)}`}>
                                    {result.team1}
                                </div>
                                <div className="font-bold text-gray-700">
                                    {result.score?.split('-')[0] || '-'}
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className={`'text-gray-600' ${getWinnerStyle(result.score, false)}`}>
                                    {result.team2}
                                </div>
                                <div className="font-bold text-gray-700">
                                    {result.score?.split('-')[1] || '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {totalPages > 1 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            )}
        </div>
    );
}; 