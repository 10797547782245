import { DUTCH_MONTHS } from './constants';
import i18next from 'i18next';

export const formatLastUpdated = (date) => {
    return date.toLocaleTimeString(i18next.language === 'nl' ? 'nl-NL' : 'en-US', { 
        hour: '2-digit', 
        minute: '2-digit'
    });
};

export const formatShortDate = (dateString) => {
    const date = new Date(dateString);
    if (i18next.language === 'nl') {
        const day = date.getDate().toString().padStart(2, '0');
        const month = DUTCH_MONTHS[date.getMonth()];
        return `${day} ${month}`;
    }
    return date.toLocaleDateString('en-US', { 
        day: '2-digit',
        month: 'long'
    });
};

export const formatShortTime = (timeString) => {
    return timeString.split('-')[0].trim();
};

export const getCalendarEvent = (match) => {
    const startDate = new Date(match.date);
    const [hours, minutes] = match.time.split('-')[0].trim().split(':');
    startDate.setHours(parseInt(hours), parseInt(minutes), 0);

    const endDate = new Date(startDate);
    endDate.setHours(endDate.getHours() + 1);

    return {
        title: `${match.team1} - ${match.team2}`,
        startTime: startDate.toISOString(),
        endTime: endDate.toISOString(),
        location: match.location
    };
};

export const formatDateDDMM = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${day}/${month}`;
}; 