import React from 'react';
import { useTranslation } from 'react-i18next';
import { RefreshCw } from 'lucide-react';
import { formatLastUpdated } from '../utils/dateUtils';

export const LastUpdatedInfo = ({ lastUpdated, handleRefresh, isRefreshing }) => {
    const { t } = useTranslation();
    
    return (
        <div className="flex items-center justify-end mb-4 text-sm text-gray-600">
            <span className="mr-2">{t('common.lastUpdated')} {formatLastUpdated(lastUpdated)}</span>
            <button 
                onClick={handleRefresh}
                disabled={isRefreshing}
                className={`p-1 rounded-full hover:bg-gray-100 transition-colors ${isRefreshing ? 'animate-spin' : ''}`}
                aria-label={t('common.refresh')}
            >
                <RefreshCw className="w-4 h-4" />
            </button>
        </div>
    );
}; 