import React from 'react';
import { Calendar } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { formatShortDate } from '../utils/dateUtils';

export const LastResult = ({ result }) => {
    const { t } = useTranslation();
    
    if (!result) return null;

    return (
        <div className="bg-white rounded-lg shadow-md p-4 mb-4">
            <div className="mb-1">
                <h2 className="text-sm font-bold uppercase tracking-wide">{t('match.lastResult')}</h2>
            </div>
            <div className="h-0.5 bg-orange-500 mb-4"></div>
            <div className="flex flex-col">
                <div className="flex justify-between items-start mb-2">
                    <div className="flex items-center text-gray-600">
                        <Calendar className="w-4 h-4 mr-2" />
                        {formatShortDate(result.date)}
                    </div>
                </div>
                <div className="grid grid-cols-[1fr_auto_1fr] items-center">
                    <div className="font-semibold text-left">{result.team1}</div>
                    <div className="text-xl font-bold text-gray-500 mx-4">
                        {result.score}
                    </div>
                    <div className="font-semibold text-right">{result.team2}</div>
                </div>
            </div>
        </div>
    );
}; 