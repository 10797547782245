import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Pencil } from 'lucide-react';

const LanguageFlag = ({ language, isSelected, onClick }) => {
    const flagEmoji = language === 'nl' ? '🇳🇱' : '🇬🇧';
    return (
        <button
            onClick={() => onClick(language)}
            className={`flex items-center gap-2 px-3 py-1.5 rounded-md transition-colors ${
                isSelected ? 'bg-gray-200' : 'hover:bg-gray-100'
            }`}
        >
            <span className="text-lg">{flagEmoji}</span>
        </button>
    );
};

export const PreferencesSection = ({
    isEditingPreferences,
    selectedSeason,
    setSelectedSeason,
    seasons,
    selectedCompetition,
    setSelectedCompetition,
    selectedTeam,
    setSelectedTeam,
    competitions,
    availableTeams,
    handleSavePreferences,
    handleEditPreferences
}) => {
    const { t, i18n } = useTranslation();
    
    const handleLanguageChange = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('preferredLanguage', language);
    };

    // Set default season to the latest one (highest id)
    useEffect(() => {
        if (seasons.length > 0 && !selectedSeason) {
            const latestSeason = seasons.reduce((latest, current) => 
                parseInt(current.id) > parseInt(latest.id) ? current : latest
            );
            setSelectedSeason(latestSeason.id);
        }
    }, [seasons, selectedSeason, setSelectedSeason]);

    return (
        <div className="bg-white rounded-lg shadow-md p-3 mb-4">
            {isEditingPreferences ? (
                <div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
                        <div>
                            <label htmlFor="season" className="block mb-2">{t('preferences.season')}:</label>
                            <select
                                id="season"
                                value={selectedSeason}
                                onChange={(e) => {
                                    setSelectedSeason(e.target.value);
                                    setSelectedCompetition('');
                                    setSelectedTeam('');
                                }}
                                className="w-full p-2 border rounded"
                            >
                                <option value="">{t('preferences.selectSeason')}</option>
                                {seasons.map((season) => (
                                    <option key={season.id} value={season.id}>{season.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="competition" className="block mb-2">{t('preferences.competition')}:</label>
                            <select
                                id="competition"
                                value={selectedCompetition}
                                onChange={(e) => {
                                    setSelectedCompetition(e.target.value);
                                    setSelectedTeam('');
                                }}
                                className="w-full p-2 border rounded"
                                disabled={!selectedSeason}
                            >
                                <option value="">{t('preferences.selectCompetition')}</option>
                                {competitions.map((comp) => (
                                    <option key={comp.pouleId} value={comp.pouleId}>{comp.name}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="team" className="block mb-2">{t('match.team')}:</label>
                            <select
                                id="team"
                                value={selectedTeam}
                                onChange={(e) => setSelectedTeam(e.target.value)}
                                className="w-full p-2 border rounded"
                                disabled={!selectedCompetition}
                            >
                                <option value="">{t('preferences.selectTeam')}</option>
                                {availableTeams.map((team) => (
                                    <option key={team.name} value={team.name}>{team.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">{t('common.language')}:</label>
                        <div className="flex gap-2">
                            <LanguageFlag
                                language="nl"
                                isSelected={i18n.language === 'nl'}
                                onClick={handleLanguageChange}
                            />
                            <LanguageFlag
                                language="en"
                                isSelected={i18n.language === 'en'}
                                onClick={handleLanguageChange}
                            />
                        </div>
                    </div>
                    <button
                        onClick={handleSavePreferences}
                        disabled={!selectedSeason || !selectedCompetition || !selectedTeam}
                        className={`px-4 py-2 rounded transition-colors ${
                            !selectedSeason || !selectedCompetition || !selectedTeam
                                ? 'bg-gray-300 cursor-not-allowed'
                                : 'bg-gray-500 text-white hover:bg-gray-600'
                        }`}
                    >
                        {t('preferences.save')}
                    </button>
                </div>
            ) : (
                <div className="flex justify-between items-center">
                    <div className="flex items-center gap-4">
                        {selectedTeam && (
                            <div>
                                {selectedTeam}
                            </div>
                        )}
                    </div>
                    <button
                        onClick={handleEditPreferences}
                        className="text-gray-600 hover:text-gray-800 transition-colors"
                    >
                        <Pencil className="w-4 h-4" />
                    </button>
                </div>
            )}
        </div>
    );
}; 